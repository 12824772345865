import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import {
    SprkCard,
    SprkInput,
    SprkInputContainer,
    SprkButton,
    SprkSpinner,
    SprkHeading,
} from '@sparkdesignsystem/spark-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginButton from './LoginButton';

class Settings extends React.Component {
    state = {
        name: null,
        loading: true,
        userName: null,
    }

    constructor(props) {
        super(props);
        this.apiUrl = process.env.REACT_APP_API_URL;

        this.nameInputRef = React.createRef();
    }
    
    toast = (text) => {
        toast.success(text, {
            position: "bottom-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    toastError = (text) => {
        toast.error(text, {
            position: "bottom-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    componentDidMount = () => {
        this.getProfile((data) => {
            if (!data) {
                return;
            }

            _this.setState({
                loading: false,
                userName: data.userName,
            }, () => {
                this.nameInputRef.current.value = data.userName;
            });
        });
        let _this = this;
        let intervalId = setInterval(() => {
            if (_this.state.userName) {
                clearInterval(intervalId);
                return;
            }
            _this.getProfile((data) => {
                if (!data) {
                    return;
                }

                _this.setState({
                    loading: false,
                    userName: data.userName,
                }, () => {
                    this.nameInputRef.current.value = data.userName;
                });
            })
        }, 500);
    }

    getToken = () => {
        let token = document.getElementById('token');
        return token ? token.innerHTML ? token.innerHTML.trim() : '' : '';
    }

    getProfile = (callback) => {
        const token = this.getToken();
        if (!token) {
            if (typeof callback === 'function') {
                return callback(null);
            }
            return null;
        }

        fetch(this.apiUrl + '/user', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            console.log(err);
            if (typeof callback === 'function') {
                callback(null);
            }
        });   
    }

    updateName = (userName, callback) => {
        const token = this.getToken();
        if (!token) {
            if (typeof callback === 'function') {
                return callback(null);
            }
            return null;
        }

        fetch(this.apiUrl + '/name', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userName: userName,
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data && data.error) {
                this.toastError('Name must not be empty');
            }
            else {
                this.toast('Saved name');
            }
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            console.log(err);
            this.toastError('Error while saving name');
            if (typeof callback === 'function') {
                callback(null);
            }
        });   
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.updateName(this.nameInputRef.current.value);
        }
    }

    render = () => {
        const { isAuthenticated, isLoading } = this.props.auth0;

        let toastContainer = (
            <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        );

        if (!isLoading && !isAuthenticated) {
            return (
                <div>
                    <SprkHeading
                        additionalClasses="game-header"
                        element="h1"
                        variant="displayTwo"
                        idString="heading-page-title"
                    >
                        Please login.
                    </SprkHeading>
                    <br/>
                    Login to view your settings.<br/><br/>
                    <LoginButton/>
                    <LoginButton className="ml-30" textOverride="Sign up" signUp={true} />
                </div>
            );
        }

        if (this.state.loading) {
            return (
                <SprkButton
                    variant="tertiary"
                    idString="button-9"
                    analyticsString="button-9-analytics"
                    isSpinning
                >
                    <SprkSpinner variant="secondary" />
                </SprkButton>
            );
        }

        return (
            <div>
                {toastContainer}
                <SprkHeading
                    additionalClasses="game-header mb-10"
                    element="h2"
                    variant="displayTwo"
                    idString="heading-page-title"
                >
                    Settings
                </SprkHeading>
                <SprkCard additionalClasses="p-30 center">
                    <SprkHeading
                        additionalClasses="game-header mb-10"
                        element="h2"
                        variant="displayFive"
                        idString="heading-page-title"
                    >
                        Name
                    </SprkHeading>
                    <SprkInput
                        autoComplete="off"
                        id="name-input"
                        type="text"
                        forwardedRef={this.nameInputRef}
                        onKeyDown={this._handleKeyDown}
                    />
                    <SprkButton additionalClasses="mt-20" id="button-copy" idString="button-home" onClick={() => {
                        this.updateName(this.nameInputRef.current.value);
                    }}>
                        Save name
                    </SprkButton>
                </SprkCard>
                <div className="footer">
                    <a href="/privacy-policy">Privacy Policy</a><br/>
                    <a href="/terms-of-service">Terms of Service</a><br/>
                  </div>
            </div>
        );
    }

}

export default withAuth0(Settings);
