import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    SprkButton,
} from '@sparkdesignsystem/spark-react';
import { setCookie } from "./Helpers";

const LoginButton = (props) => {
  const { loginWithRedirect } = useAuth0();

  const text = props.textOverride ? props.textOverride : 'Log in';
  const buttonType = props.buttonTypeOverride ? props.buttonTypeOverride : 'primary';
  return <SprkButton additionalClasses={props.className} onClick={() => {
        setCookie('login-from', window.location.pathname, 1);
        loginWithRedirect({
        screen_hint: props.signUp ? 'signup' : 'login',
        });
    }}>
        {text}
    </SprkButton>;
};

export default LoginButton