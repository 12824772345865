import React from 'react';
import {
    SprkHeading,
    SprkCard,
} from '@sparkdesignsystem/spark-react';

class About extends React.Component {
    constructor(props) {
        super(props);
        
    }
    render() {
        return (
            <div>
                <SprkHeading additionalClasses="mt-30 mb-10" element="h2" variant="displayTwo" idString="heading-two">
                    About
                </SprkHeading>
                <SprkCard additionalClasses="wide p-30 center">
                    <img src="/undraw_Developer_activity_re_39tg.svg" style={{ maxWidth: '300px', margin: 'auto' }}/>
                    <br/>
                    <p>
                        <strong>www.thinklikeabot.com</strong> was created by a web developer with a passion for dumb games.
                        <br/>
                        <br/>
                        Let me know what you think (bugs, feature requests, compliments, complaints) by emailing me at <a href="mailto:ckm13d@my.fsu.edu">ckm13d@my.fsu.edu</a>
                    </p>
                </SprkCard>
                <br/>

                <br/>
                <SprkCard additionalClasses="p-30 center mb-30 wide">
                    <h3>Like the game?</h3>
                    <img src="/undraw_Appreciation_re_p6rl.svg" style={{ maxWidth: '300px', margin: 'auto' }}/>
                    <br/>
                    <p>
                        We depend on donations to keep the lights on.<br/>
                        It costs us money to run the server and to use the artificial intelligence API for this site.<br/>
                        If you liked playing, consider donating!
                        <br/>
                        <br/>
                    </p>

                    <a href='https://ko-fi.com/R5R06L8RJ' target='_blank'><img height='36' style={{border:'0px', height:'36px'}} src='https://cdn.ko-fi.com/cdn/kofi2.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com' /></a>

                    <br/>

                    <form action="https://www.paypal.com/donate" method="post" target="_top">
                        <input type="hidden" name="business" value="VZ89D2KPXRTEG" />
                        <input type="hidden" name="no_recurring" value="0" />
                        <input type="hidden" name="item_name" value="www.thinklikeabot.com" />
                        <input type="hidden" name="currency_code" value="USD" />
                        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                        <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                    </form>
                </SprkCard>
                <div className="footer">
                    <a href="/privacy-policy">Privacy Policy</a><br/>
                    <a href="/terms-of-service">Terms of Service</a><br/>
              </div>
            </div>
        );
    }
}

export default About;
