import React from 'react';
import {
    SprkButton,
    SprkSpinner,
    SprkCard,
    SprkStack,
    SprkStackItem,
    SprkText,
    SprkHeading,
    SprkInputContainer,
    SprkInput,
    SprkLabel,
    SprkList,
    SprkListItem,
    SprkTable,
    SprkTabs,
    SprkTabsPanel,
    SprkModal,
    SprkAccordion,
    SprkAccordionItem,
} from '@sparkdesignsystem/spark-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProgressBar from "@ramonak/react-progress-bar";
import { withAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router';
import LoginLink from './LoginLink';
import LoginButton from './LoginButton';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import {isMobile, MobileView, BrowserView, } from 'react-device-detect';
import { getCategoryLabel, getCategory, getTypeLabel, secondsToTime, getTimeStr } from './Helpers';
var qs = require('qs');

class Game extends React.Component {
    state = {
        started: false,
        unauthenticated: false,
        notFound: false,
        error: false,
        loading: false,
        images: null,
        currentImage: null,
        imageUrl: null,
        labels: null,
        guesses: [],
        matchingGuesses: [],
        showingResults: false,
        finished: false,
        score: 0,
        thisRoundScore: 0,
        roundScores: [],
        user: null,
        multiplayer: false,
        gameId: null,
        multiplayerStep: 0,
        singleplayerStep: 0,
        allInfos: null,
        timeLimitSetting: 7,
        guessLimitSetting: 6, 
        guessesLeft: -1,  
        gameIsStarted: false,
        timeLeftSeconds: -1,
        time: {},
        selectedCategory: 'random',
        selectedCategoryLabel: 'Random',
        isInstructionsModalVisible: false,
        preselectCategory: null,
        preselectType: 'classic',
        selectedType: null,
        selectedTypeLabel: null,
        streakOver: false,
    };

    constructor(props) {
        super(props);
        this.apiUrl = process.env.REACT_APP_API_URL;
        this.guessInputRef = React.createRef();

        this.timerSliderRef = React.createRef();
        this.guessSliderRef = React.createRef();

        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);

        // Initialize an agent at application startup.
        this.fpPromise = FingerprintJS.load()

        let svgs = [
            '/undraw_Artificial_intelligence_re_enpp.svg',
            '/undraw_Chat_bot_re_e2gj.svg',
            '/undraw_Firmware_jw6u.svg',
            '/undraw_online_test_gba7.svg',
        ];
        this.randomSvg = svgs[Math.floor(Math.random() * svgs.length)];


        this.svgs2 = [
            '/undraw_Artificial_intelligence_re_enpp.svg',
            '/undraw_Chat_bot_re_e2gj.svg',
            '/undraw_Firmware_jw6u.svg',
            '/undraw_online_test_gba7.svg',
            '/undraw_game_world_0o6q.svg',
            '/undraw_old_day_6x25.svg',
            '/undraw_snow_games_ohkc.svg',
            '/undraw_playing_fetch_cm19.svg',
            '/undraw_starry_window_ppm0.svg',
            '/undraw_moments_0y20.svg',
            '/undraw_Polaroid_re_481f.svg',
            '/undraw_videographer_nnc7.svg',
            '/undraw_Organize_photos_re_ogcy.svg',
            '/undraw_Surveillance_re_8tkl.svg',
            '/undraw_programmer_imem.svg',
            '/undraw_content_team_3epn.svg',
            '/undraw_Dev_focus_re_6iwt.svg',
            '/undraw_pair_programming_njlp.svg',
            '/undraw_dev_productivity_umsq.svg',
            '/undraw_Code_thinking_re_gka2.svg',

        ];
        this.pickRandomSvg2();

        this.svgs3 = [
            '/undraw_unicorn_dp2f.svg',
            '/undraw_finish_line_katerina_limpitsouni_xy20.svg',
            '/undraw_Astronaut_re_8c33.svg',
            '/undraw_festivities_tvvj.svg',
            '/undraw_home_run_oerh.svg',
            '/undraw_High_five_re_jy71.svg',
            '/undraw_Celebration_re_kc9k.svg',
            '/undraw_well_done_i2wr.svg',
            '/undraw_winners_ao2o.svg',
            '/undraw_awesome_rlvy.svg',
            '/undraw_Feeling_of_joy_re_cqko.svg',
            '/undraw_Powerful_re_frhr.svg',
            '/undraw_happy_feeling_slmw.svg',
            '/undraw_feeling_happy_jymo.svg',
            '/undraw_My_universe_re_txot.svg',
            '/undraw_Ride_till_I_can_no_more_44wq.svg',
        ];
        this.pickRandomSvg3();
    }

    pickRandomSvg2 = () => {
        this.randomSvg2 = this.svgs2[Math.floor(Math.random() * this.svgs2.length)];
    }

    pickRandomSvg3 = () => {
        this.randomSvg3 = this.svgs3[Math.floor(Math.random() * this.svgs3.length)];
    }

    getTimeLeftString = () => {
        let str = '';
        if (this.state.time.m) {
            str += this.state.time.m + ' minute';
            if (this.state.time.m !== 1) {
                str += 's';
            }
        }
        if (this.state.time.s) {
            if (str) {
                str += ' ';
            }
            str += this.state.time.s + ' second';
            if (this.state.time.s !== 1) {
                str += 's';
            }
        }
        str += ' left';
        return str;
    }

    getGuessesLeftStr = () => {
        let str = '';
        if (this.state.guessesLeft === -1 || this.state.guessesLeft === 6) {
            return 'Unlimited wrong guesses left';
        }
        str += this.state.guessesLeft;
        str += ' wrong guess';
        if (this.state.guessesLeft !== 1) {
            str += 'es';
        }
        str += ' left';
        return str;
    } 


    getTimeLeftPercentage = () => {
        let percentage = 100;
        if (this.state.timeLeftSeconds) {
            percentage = this.state.timeLeftSeconds / this.state.timeLimitSeconds;
            percentage *= 100;
        }
        return percentage;
    }

    getGuessesLeftPercentage = () => {
        let percentage = 100;
        if (this.state.guessesLeft !== -1) {
            percentage = this.state.guessesLeft / this.state.guessLimitSetting;
            percentage *= 100;
        }
        return percentage;
    }

    startTimer() {
        if (this.timer == 0 && this.state.timeLeftSeconds > 0) {
          this.timer = setInterval(this.countDown, 1000);
        }
    }

    setOpenCategoryModal = (value) => {
        this.setState({
            isCategoryModalVisible: value,
        });
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.timeLeftSeconds - 1;
        this.setState({
            time: secondsToTime(seconds),
            timeLeftSeconds: seconds,
        });
        
        // Check if we're at zero.
        if (seconds === 0) { 
            clearInterval(this.timer);
            this.timer = 0;
            this.showResults();
        }
    }

    componentDidUpdate = () => {
        let ourCell = Array.from(document.querySelectorAll('.score-table td'))
        .find(el => el.textContent === 'auth0|61658df3cac37f0068489125');
        if (!ourCell) {
            return;
        }
        ourCell.parentElement.classList.add('our-score-row');
    }
    
    componentDidMount = () => {
        let _this = this;
        this.getUser();
        let tries = 0;
        let intervalId = setInterval(() => {
            if (_this.state.user || tries >= 5) {
                clearInterval(intervalId);
                return;
            }
            tries++;
            _this.getUser();
        }, 500);

        if (this.props.match && this.props.match.path === '/game/:id' && this.props.match.params.id) {
            this.setState({
                loading: true,
            });
            let intervalId2 = setInterval(() => {
                if (_this.state.multiplayerStep === 2 || _this.state.singleplayerStep === 2) {
                    clearInterval(intervalId2);
                    return;
                }
                _this.getGame(_this.props.match.params.id, (data) => {
                    if (data === -1) {
                        return;
                    }
                    clearInterval(intervalId2);
                    if (!data) {
                        _this.setState({
                            notFound: true,
                        })
                        return;
                    }
                    if (!data.rounds) {
                        this.setState({
                            error: true,
                        });
                        return;
                    }
                    let isDaily = false;
                    let isDailyMatch = _this.props.match.params.id.match(/\d\d\d\d-\d\d-\d\d/);
                    if (isDailyMatch && isDailyMatch.length) {
                        isDaily = true;
                    }

                    _this.setState({
                        daily: isDaily,
                        multiplayerStep: data.multiplayer ? 2 : 0,
                        singleplayerStep: !data.multiplayer ? 2 : 0,
                        gameId: _this.props.match.params.id,
                        multiplayer: data.multiplayer,
                        images: data ? data.rounds : null,
                        currentImage: 0,
                        imageUrl: data ? data.rounds[0].imageUrl : null,
                        labels: data ? data.rounds[0].labels : null,
                        loading: false,
                    }, () => {
                        _this.getAllInfosFull((data) => {
                            _this.setState({
                                allInfosFull: data,
                            });
                        });
                        _this.getGameInfo((data) => {
                            if (data === null) {
                                _this.setState({
                                    error: true,
                                });
                                return;
                            }
                            _this.setState({
                                gameIsStarted: data.started,
                                timeLimitSetting: data.timeLimitSetting,
                                timeLimitSeconds: data.timeLimitSeconds,
                                guessLimitSetting: data.guessLimitSetting,
                                selectedCategory: data.category,
                                selectedType: data.type,
                                streakOver: data.streakOver,
                            }, () => {
                                this.setState({
                                    selectedCategoryLabel: getCategoryLabel(this.state.selectedCategory),
                                    selectedTypeLabel: getTypeLabel(this.state.selectedType),
                                    guessLimitSetting: this.state.selectedType === 'streak' ? 1 : this.state.guessLimitSetting,
                                });
                                let timeLeftVar = secondsToTime(this.state.timeLimitSeconds);
                                this.setState({ time: timeLeftVar });                        
                            });
                        });
                    });
                });
            }, 500);
        }

        if (this.props.match && this.props.match.path === '/singleplayer_game/:id' && this.props.match.params.id) {
            this.setState({
                loading: true,
            });
            let intervalId2 = setInterval(() => {
                if (_this.state.multiplayerStep === 2 || _this.state.singleplayerStep === 2) {
                    clearInterval(intervalId2);
                    return;
                }
                _this.getSinglePlayerGame(_this.props.match.params.id, (data) => {
                    if (data === -1) {
                        return;
                    }
                    if (!data.rounds) {
                        this.setState({
                            error: true,
                        });
                        return;
                    }
                    clearInterval(intervalId2);
                    if (!data) {
                        _this.setState({
                            notFound: true,
                        })
                        return;
                    }
                    _this.setState({
                        multiplayerStep: data.multiplayer ? 2 : 0,
                        singleplayerStep: !data.multiplayer ? 2 : 0,
                        gameId: _this.props.match.params.id,
                        multiplayer: data.multiplayer,
                        images: data ? data.rounds : null,
                        currentImage: 0,
                        imageUrl: data ? data.rounds[0].imageUrl : null,
                        labels: data ? data.rounds[0].labels : null,
                        loading: false,
                    }, () => {
                        _this.getAllInfosFullSinglePlayer((data) => {
                            _this.setState({
                                allInfosFull: data,
                            });
                        });
                        _this.getSinglePlayerGameInfo((data) => {
                            if (data === null) {
                                _this.setState({
                                    error: true,
                                });
                                return;
                            }
                            _this.setState({
                                gameIsStarted: data.started,
                                timeLimitSetting: data.timeLimitSetting,
                                timeLimitSeconds: data.timeLimitSeconds,
                                guessLimitSetting: data.guessLimitSetting,
                                selectedCategory: data.category,
                                selectedType: data.type,
                                streakOver: data.streakOver,
                            }, () => {
                                this.setState({
                                    selectedCategoryLabel: getCategoryLabel(this.state.selectedCategory),
                                    selectedTypeLabel: getTypeLabel(this.state.selectedType),
                                    guessLimitSetting: this.state.selectedType === 'streak' ? 1 : this.state.guessLimitSetting,
                                });
                                let timeLeftVar = secondsToTime(this.state.timeLimitSeconds);
                                this.setState({ time: timeLeftVar });                        
                            });
                        });
                    });
                });
            }, 500);
        }
    }

    getToken = () => {
        let token = document.getElementById('token');
        return token ? token.innerHTML ? token.innerHTML.trim() : '' : '';
    }

    goHome = () => {
        this.setState({
            started: false,
            notFound: false,
            error: false,
            loading: false,
            images: null,
            currentImage: null,
            imageUrl: null,
            labels: null,
            guesses: [],
            matchingGuesses: [],
            showingResults: false,
            finished: false,
            score: 0,
            thisRoundScore: 0,
            roundScores: [],
        });
    }

    getUser = (callback) => {
        const token = this.getToken();
        if (!token) {
            if (typeof callback === 'function') {
                return callback(null);
            }
        }

        fetch(this.apiUrl + '/user', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(response => response.json())
        .then(data => {
            this.setState({
                user: data,
            }, () => {
                if (typeof callback === 'function') {
                    callback(data);
                }
            });
        }).catch(err => {
            if (typeof callback === 'function') {
                callback(null);
            }
        });      
    }

    addMoreImages = async (callback) => {
        const fp = await this.fpPromise;
        const result = await fp.get();
        const visitorId = result.visitorId;

        const token = this.getToken();

        fetch(this.apiUrl + '/add_more_images/' + this.state.gameId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: visitorId,
                token: token,
            })
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            if (typeof callback === 'function') {
                callback(null);
            }
        });      
    }


    setSinglePlayerGameSettings = async (callback) => {
        const fp = await this.fpPromise;
        const result = await fp.get();
        const visitorId = result.visitorId;

        const token = this.getToken();

        fetch(this.apiUrl + '/singleplayer_set_game_settings/' + this.state.gameId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                guessLimitSetting: this.state.guessLimitSetting,
                timeLimitSetting: this.state.timeLimitSetting,
                category: this.state.selectedCategory,
                userId: visitorId,
                token: token,
            })
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            if (typeof callback === 'function') {
                callback(null);
            }
        });      
    }

    setGameSettings = async (callback) => {
        const token = this.getToken();
        if (!token) {
            return callback(null);
        }

        fetch(this.apiUrl + '/set_game_settings/' + this.state.gameId, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                guessLimitSetting: this.state.guessLimitSetting,
                timeLimitSetting: this.state.timeLimitSetting,
                category: this.state.selectedCategory,
            })
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            if (typeof callback === 'function') {
                callback(null);
            }
        });      
    }

    startSinglePlayerGame = async (category, type, callback) => {
        const fp = await this.fpPromise;
        const result = await fp.get();
        const visitorId = result.visitorId;

        const token = this.getToken();

        fetch(this.apiUrl + '/start_singleplayer_game', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                multiplayer: false,
                userId: visitorId,
                token: token,
                category: category,
                type: type,
            })
        })
        .then(response => response.json())
        .then(data => {
            window.location.href = '/singleplayer_game/' + data.gameId;
        }).catch(err => {
            if (typeof callback === 'function') {
                callback(null);
            }
        });      
    }

    startMultiplayerGame = (category, type, callback) => {
        const token = this.getToken();
        if (!token) {
            if (typeof callback === 'function') {
                return callback(null);
            }
            return null;
        }

        fetch(this.apiUrl + '/start_game', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                multiplayer: true,
                category: category,
                type: type,
            })
        })
        .then(response => response.json())
        .then(data => {
            window.location.href = '/game/' + data.gameId;
        }).catch(err => {
            if (typeof callback === 'function') {
                callback(null);
            }
        });      
    }

    startDailyChallengeGame = async (category, type, callback) => {
        const fp = await this.fpPromise;
        const result = await fp.get();
        const visitorId = result.visitorId;

        const token = this.getToken();

        fetch(this.apiUrl + '/start_daily_challenge', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                multiplayer: true,
                category: category,
                type: type,
                userId: visitorId,
                token: token,
            })
        })
        .then(response => response.json())
        .then(data => {
            window.location.href = '/game/' + data.gameId;
        }).catch(err => {
            if (typeof callback === 'function') {
                callback(null);
            }
        });      
    }

    getAllInfos = (callback) => {
        const token = this.getToken();
        if (!token) {
            if (typeof callback === 'function') {
                return callback(null);
            }
        }

        fetch(this.apiUrl + '/game_infos/' + this.state.gameId + '/' + this.state.currentImage, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            console.log(err);
            if (typeof callback === 'function') {
                callback(null);
            }
        });   
    }

    getAllInfosSinglePlayer = async (callback) => {
        const fp = await this.fpPromise;
        const result = await fp.get();
        const visitorId = result.visitorId;

        const token = this.getToken();

        fetch(this.apiUrl + '/singleplayer_game_infos/' + this.state.gameId + '/' + this.state.currentImage, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: visitorId,
                token: token,
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            console.log(err);
            if (typeof callback === 'function') {
                callback(null);
            }
        });   
    }

    getAllInfosFull = (callback) => {
        const token = this.getToken();
        if (!token) {
            if (typeof callback === 'function') {
                return callback(null);
            }
            return null;
        }

        fetch(this.apiUrl + '/game_infos_full/' + this.state.gameId, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            console.log(err);
            if (typeof callback === 'function') {
                callback(null);
            }
        });   
    }

    getAllInfosFullSinglePlayer = async (callback) => {
        const fp = await this.fpPromise;
        const result = await fp.get();
        const visitorId = result.visitorId;

        const token = this.getToken();

        fetch(this.apiUrl + '/singleplayer_game_infos_full/' + this.state.gameId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: visitorId,
                token: token,
            })
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            console.log(err);
            if (typeof callback === 'function') {
                callback(null);
            }
        });   
    }

    sendResults = (callback) => {
        const token = this.getToken();
        if (!token) {
            if (typeof callback === 'function') {
                return callback(null);
            }
            return null;
        }

        fetch(this.apiUrl + '/game_results/' + this.state.gameId, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                guesses: this.state.guesses,
                round: this.state.currentImage,
            })
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            console.log(err);
            if (typeof callback === 'function') {
                callback(null);
            }
        });   
    }

    sendResultsSinglePlayer = async (callback) => {
        const fp = await this.fpPromise;
        const result = await fp.get();
        const visitorId = result.visitorId;

        const token = this.getToken();

        fetch(this.apiUrl + '/singleplayer_game_results/' + this.state.gameId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                guesses: this.state.guesses,
                round: this.state.currentImage,
                userId: visitorId,
                token: token,
            })
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            console.log(err);
            if (typeof callback === 'function') {
                callback(null);
            }
        });   
    }

    getGameInfo = (callback) => {
        const token = this.getToken();
        if (!token) {
            if (typeof callback === 'function') {
                return callback(-1);
            }
            return -1;
        }

        fetch(this.apiUrl + '/game_info/' + this.state.gameId, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            console.log(err);
            if (typeof callback === 'function') {
                callback(null);
            }
        });   
    }

    getSinglePlayerGameInfo = async (callback) => {
        const fp = await this.fpPromise;
        const result = await fp.get();
        const visitorId = result.visitorId;
        
        const token = this.getToken();

        fetch(this.apiUrl + '/singleplayer_game_info/' + this.state.gameId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: visitorId,
                token: token,
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            console.log(err);
            if (typeof callback === 'function') {
                callback(null);
            }
        });   
    }

    getGame = (gameId, callback) => {
        const token = this.getToken();
        if (!token) {
            if (typeof callback === 'function') {
                return callback(-1);
            }
            return -1;
        }

        fetch(this.apiUrl + '/game/' + gameId, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            console.log(err);
            if (typeof callback === 'function') {
                callback(null);
            }
        });      
    }

    getSinglePlayerGame = async (gameId, callback) => {
        const fp = await this.fpPromise;
        const result = await fp.get();
        const visitorId = result.visitorId;

        const token = this.getToken();

        fetch(this.apiUrl + '/singleplayer_game/' + gameId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: visitorId,
                token: token,
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            console.log(err);
            if (typeof callback === 'function') {
                callback(null);
            }
        });      
    }

    internalHandleGuess = (guess) => {
        if (this.state.showingResults) {
            return;
        }
        let guesses = this.state.guesses;
        let matchingGuesses = this.state.matchingGuesses;
        if (!guess || !matchingGuesses) {
            return;
        }
        let matchingLabels = this.getAllMatchingLabels(guess);
        let contained = false;
        for (let i = 0; i < matchingGuesses.length; i++) {
            if (matchingGuesses[i].toLowerCase().trim() === guess.toLowerCase().trim()) {
                contained = true;
                break;
            }
        }
        if (contained || guesses.indexOf(guess) !== -1) {
        }
        else {
            let score = this.state.score;
            let thisRoundScore = this.state.thisRoundScore;
            let newGuessesLeft = this.state.guessesLeft;
            if (this.isCorrect(guess)) {
                //let newScore = Math.round(5000 * (1 / this.getLabelsCount()));
                if (this.state.selectedType === 'classic') {
                    let newScore = 100;
                    score += newScore;
                    thisRoundScore += newScore;
                }
            }
            else {
                if (newGuessesLeft !== 6) {
                    newGuessesLeft = newGuessesLeft - 1;
                }
            }
            guesses.unshift(guess);
            matchingGuesses = matchingGuesses.concat(matchingLabels);
            this.setState({
                thisRoundScore: thisRoundScore,
                score: score,
                guesses: guesses,
                matchingGuesses: matchingGuesses,
                guessesLeft: newGuessesLeft,
            }, () => {
                if (this.state.guessesLeft === 0) {
                    if (this.state.selectedType === 'classic') {
                        this.showResults();
                    }
                    else {
                        this.setState({
                            streakOver: true,
                        }, () => {
                            this.showResults();
                        })
                    }
                }
                else if (this.getLabelsLeftCount() === 0) {
                    if (this.state.selectedType === 'classic') {
                        this.showResults();
                    }
                    else {
                        let newScore = 1;
                        score += newScore;
                        thisRoundScore += newScore;
                        this.setState({
                            thisRoundScore: thisRoundScore,
                            score: score,
                        }, () => {
                            this.showResults();
                        });
                    }
                }
            });
        }
    }

    handleGuess = () => {
        let guess = this.guessInputRef.current.value.toLowerCase().trim();
        this.internalHandleGuess(guess);
        this.guessInputRef.current.value = '';
        this.guessInputRef.current.focus();
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            let guess = e.target.value.toLowerCase().trim();
            this.internalHandleGuess(guess);
            e.target.value = '';
        }
    }

    getLabelsCount = () => {
        if (!this.state.labels) {
            return 0;
        }
        if (this.state.selectedType === 'streak') {
            return 1;
        }
        return this.state.labels.Labels.length;
    }

    getCorrectCount = () => {
        if (!this.state.guesses) {
            return 0;
        }
        let correctCount = 0;
        for (let i = 0; i < this.state.guesses.length; i++) {
            if (this.isCorrect(this.state.guesses[i])) {
                correctCount++;
            }
        }
        return correctCount;
    }

    getLabelsLeftCount = () => {
        let total = this.getLabelsCount();
        let correct = this.getCorrectCount();
        return total - correct;
    }

    isCorrect = (label, labelsOverride) => {
        let labels = this.state.labels;
        if (labelsOverride) {
            labels = labelsOverride;
        }
        if (!labels) {
            return false;
        }
        let correct = false;
        for (let i = 0; i < labels.Labels.length; i++) {
            let thisLabelNames = labels.Labels[i].Names;
            for (let j = 0; j  < thisLabelNames.length; j++) {
                let thisLabel = thisLabelNames[j].toLowerCase().trim();
                if (label.toLowerCase().trim() === thisLabel) {
                    correct = true;
                    break;
                }
            }
        }
        return correct;
    }

    getAllMatchingLabels = (label) => {
        if (!this.state.labels) {
            return false;
        }
        let matchingLabels = [];
        for (let i = 0; i < this.state.labels.Labels.length; i++) {
            let thisLabelNames = this.state.labels.Labels[i].Names;
            for (let j = 0; j  < thisLabelNames.length; j++) {
                let thisLabel = thisLabelNames[j].toLowerCase().trim();
                if (label.toLowerCase().trim() === thisLabel) {
                    matchingLabels = thisLabelNames;
                    break;
                }
                if (matchingLabels.length) {
                    break;
                }
            }
        }
        return matchingLabels;
    }

    showResults = () => {
        clearInterval(this.timer);
        this.timer = 0;
        this.setState({
            timeLeftSeconds: 0,
            showingResults: true,
        });
        if (this.state.multiplayer) {
            this.sendResults(() => {
                this.getAllInfos((data) => {
                    if (data) {
                        this.setState({
                            allInfos: data,
                        });
                    }
                });
            });
        }
        else {
            this.sendResultsSinglePlayer(() => {
                this.getAllInfosSinglePlayer((data) => {
                    if (data) {
                        this.setState({
                            allInfos: data,
                        });
                    }
                });
            });
        }
    }

    startSinglePlayer = (category, type) => {
        this.setState({
            loading: true,
        });
        this.startSinglePlayerGame(category, type);
    }

    startMultiplayer = (category, type) => {
        this.setState({
            loading: true,
        });
        this.startMultiplayerGame(category, type);
    }

    startDailyChallenge = (category, type) => {
        this.setState({
            loading: true,
        });
        this.startDailyChallengeGame(category, type);
    }

    realStartMultiplayerGame = () => {
        this.setGameSettings((d) => {
            this.setState({
                timeLimitSeconds: d.timeLimitSeconds,
            }, () => {
                this.getGameInfo((data) => {
                    if (data === null || data === -1) {
                        this.setState({
                            error: true,
                        });
                        return;
                    }
                    let finished = false;
                    let imageUrl = null;
                    let labels = null;

                    if (data.type === 'streak' && !data.streakOver && data.currentImage % 5 === 0 && this.state.images.length <= data.currentImage) {
                        this.addMoreImages((d) => {
                            this.setState({
                                images: d.rounds,
                            }, () => {
                                imageUrl = this.state.images[data.currentImage].imageUrl;
                                labels = this.state.images[data.currentImage].labels;
                                this.setState({
                                    finished: finished,
                                    multiplayer: false,
                                    started: true,
                                    currentImage: data.currentImage,
                                    imageUrl: imageUrl,
                                    labels: labels,
                                    score: data.score,
                                    thisRoundScore: 0,
                                    roundScores: data.roundScores,
                                    guesses: [],
                                    matchingGuesses: [],
                                    singleplayerStep: 3,
                                    timeLeftSeconds: this.state.timeLimitSeconds,
                                    guessesLeft: this.state.guessLimitSetting,
                                }, () => {
                                    let timeLeftVar = secondsToTime(this.state.timeLimitSeconds);
                                    this.setState({ time: timeLeftVar }, () => {
                                        this.startTimer();
                                    }); 
                                });
                            });
                        });
                        return;
                    }

                    if (
                        (data.type === 'streak' && data.streakOver) ||
                        (data.type === 'classic' && data.currentImage >= 5)
                    ) {
                        finished = true;
                        this.getAllInfosFull((data) => {
                            this.setState({
                                allInfosFull: data,
                            });
                        });
                    }
                    else {
                        imageUrl = this.state.images[data.currentImage].imageUrl;
                        labels = this.state.images[data.currentImage].labels;
                    }
                    this.setState({
                        finished: finished,
                        multiplayer: true,
                        started: true,
                        currentImage: data.currentImage,
                        imageUrl: imageUrl,
                        labels: labels,
                        score: data.score,
                        thisRoundScore: 0,
                        roundScores: data.roundScores,
                        guesses: [],
                        matchingGuesses: [],
                        multiplayerStep: 3,
                        timeLeftSeconds: this.state.timeLimitSeconds,
                        guessesLeft: this.state.guessLimitSetting,
                    }, () => {
                        let timeLeftVar = secondsToTime(this.state.timeLimitSeconds);
                        this.setState({ time: timeLeftVar }, () => {
                            this.startTimer();
                        }); 
                    });
                });
            });
        });
    }

    realStartSinglePlayerGame = () => {
        this.setSinglePlayerGameSettings((d) => {
            this.setState({
                timeLimitSeconds: d.timeLimitSeconds,
            }, () => {
                this.getSinglePlayerGameInfo((data) => {
                    if (data === null) {
                        this.setState({
                            error: true,
                        });
                        return;
                    }
                    let finished = false;
                    let imageUrl = null;
                    let labels = null;

                    if (data.type === 'streak' && !data.streakOver && data.currentImage % 5 === 0 && this.state.images.length <= data.currentImage) {
                        this.addMoreImages((d) => {
                            this.setState({
                                images: d.rounds,
                            }, () => {
                                imageUrl = this.state.images[data.currentImage].imageUrl;
                                labels = this.state.images[data.currentImage].labels;
                                this.setState({
                                    finished: finished,
                                    multiplayer: false,
                                    started: true,
                                    currentImage: data.currentImage,
                                    imageUrl: imageUrl,
                                    labels: labels,
                                    score: data.score,
                                    thisRoundScore: 0,
                                    roundScores: data.roundScores,
                                    guesses: [],
                                    matchingGuesses: [],
                                    singleplayerStep: 3,
                                    timeLeftSeconds: this.state.timeLimitSeconds,
                                    guessesLeft: this.state.guessLimitSetting,
                                }, () => {
                                    let timeLeftVar = secondsToTime(this.state.timeLimitSeconds);
                                    this.setState({ time: timeLeftVar }, () => {
                                        this.startTimer();
                                    }); 
                                });
                            });
                        });
                        return;
                    }

                    if (
                        (data.type === 'streak' && data.streakOver) ||
                        (data.type === 'classic' && data.currentImage >= 5)
                    ) {
                        finished = true;
                        this.getAllInfosFullSinglePlayer((data) => {
                            this.setState({
                                allInfosFull: data,
                            });
                        });
                    }
                    else {
                        imageUrl = this.state.images[data.currentImage].imageUrl;
                        labels = this.state.images[data.currentImage].labels;
                    }
                    this.setState({
                        finished: finished,
                        multiplayer: false,
                        started: true,
                        currentImage: data.currentImage,
                        imageUrl: imageUrl,
                        labels: labels,
                        score: data.score,
                        thisRoundScore: 0,
                        roundScores: data.roundScores,
                        guesses: [],
                        matchingGuesses: [],
                        singleplayerStep: 3,
                        timeLeftSeconds: this.state.timeLimitSeconds,
                        guessesLeft: this.state.guessLimitSetting,
                    }, () => {
                        let timeLeftVar = secondsToTime(this.state.timeLimitSeconds);
                        this.setState({ time: timeLeftVar }, () => {
                            this.startTimer();
                        }); 
                    });
                });  
            });
        }); 
    }

    next = () => {
        let roundScores = this.state.roundScores;
        roundScores.push(this.state.thisRoundScore);
        this.setState({
            showingResults: false,
            guesses: [],
            matchingGuesses: [],
            imageUrl: null,
            labels: null,
            thisRoundScore: 0,
            roundScores: roundScores,
            guessesLeft: this.state.guessLimitSetting,
        }, () => {
            let currentImage = this.state.currentImage;
            currentImage++;
            if (
                (this.state.selectedType === 'classic' && currentImage > this.state.images.length - 1) ||
                (this.state.selectedType === 'streak' && this.state.streakOver)
            ) {
                if (this.state.multiplayer) {
                    this.getAllInfosFull((data) => {
                        this.setState({
                            allInfosFull: data,
                            finished: true,
                        });
                    });
                }
                else {
                    this.getAllInfosFullSinglePlayer((data) => {
                        this.setState({
                            allInfosFull: data,
                            finished: true,
                        });
                    });
                }
                return;
            }
            if (this.state.selectedType === 'streak' && (currentImage) % 5 === 0 && this.state.images.length <= currentImage) {
                this.addMoreImages((d) => {
                    this.setState({
                        images: d.rounds,
                    }, () => {
                        this.setState({
                            currentImage: currentImage,
                            imageUrl: this.state.images[currentImage].imageUrl,
                            labels: this.state.images[currentImage].labels,
                            timeLeftSeconds: this.state.timeLimitSeconds,
                        }, () => {
                            this.guessInputRef.current.focus();
                            let timeLeftVar = secondsToTime(this.state.timeLimitSeconds);
                            this.setState({ time: timeLeftVar }, () => {
                                this.startTimer();
                            }); 
                        });
                    });
                });
                return;
            }
            this.setState({
                currentImage: currentImage,
                imageUrl: this.state.images[currentImage].imageUrl,
                labels: this.state.images[currentImage].labels,
                timeLeftSeconds: this.state.timeLimitSeconds,
            }, () => {
                this.guessInputRef.current.focus();
                let timeLeftVar = secondsToTime(this.state.timeLimitSeconds);
                this.setState({ time: timeLeftVar }, () => {
                    this.startTimer();
                }); 
            });
        });
    }

    playAgain = () => {
        if (this.state.multiplayer) {
            this.startMultiplayer(this.state.selectedCategory, this.state.selectedType);
        }
        else {
            this.startSinglePlayer(this.state.selectedCategory, this.state.selectedType);
        }
    }

    getGuessListLis = (guessList, key, noCheck, labelsOverride) => {
        let guesses = [];
        for (let i = 0; i < guessList.length; i++) {
            let guess = guessList[i].toLowerCase().trim();
            let result;
            if (!noCheck) {
                if (this.isCorrect(guess, labelsOverride)) {
                    result = (
                        <span className="correct">✓</span>
                    );
                }
                else {
                    result = (
                        <span className="incorrect">x</span>
                    );
                }
            }
            guesses.push(<SprkListItem key={key + '-list-item-' + i} idString={"list-item-" + i}>{guess} {result}</SprkListItem>);
        }
        return guesses;
    }

    toast = (text) => {
        toast(text, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    getTimeLimit = () => {
        this.setState({
            timeLimitSetting: this.timerSliderRef.current.state.value,
        });
    }
    
    getGuessLimit = () => {
        this.setState({
            guessLimitSetting: this.guessSliderRef.current.state.value,
        });
    }

    render() {
        let footer;
        if (this.state.redirect) {
            window.location.href = this.state.redirect;
            return '';
        }
        const { user, isAuthenticated, isLoading } = this.props.auth0;

        let toastContainer = (
            <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        );

        if (this.state.notFound) {
            if (!isLoading && !isAuthenticated && this.props.match.path === '/game/:id') {
                return (
                    <div>
                        <SprkHeading
                            additionalClasses="game-header"
                            element="h1"
                            variant="displayTwo"
                            idString="heading-page-title"
                        >
                            Please login.
                        </SprkHeading>
                        <br/>
                        Login to play multiplayer games.<br/><br/>
                        <LoginButton/>
                        <LoginButton className="ml-30" textOverride="Sign up" signUp={true} />
                    </div>
                );
            }

            return (
                <div>
                    <SprkHeading
                        additionalClasses="game-header"
                        element="h1"
                        variant="displayTwo"
                        idString="heading-page-title"
                    >
                        Uh-oh.
                    </SprkHeading>
                    <br/>
                    <img src='/undraw_Autumn_re_rwy0.svg' style={{ maxWidth: '300px'}} />
                    <br/>
                    <br/>
                    That game was not found.<br/><br/>
                    <SprkButton id="button-home" idString="button-home" onClick={() => {
                        window.location.href = '/';
                    }}>
                        Back to Home
                    </SprkButton>
                </div>
            )
        }

        if (this.state.multiplayerStep === 2 || this.state.singleplayerStep === 2) {
            let share;
            if (this.state.multiplayer) {
                share = (
                    <div>
                        <p className="mt-30">
                            Share this URL to play against your friends:
                        </p>
                        <SprkInputContainer additionalClasses="share-input-wrapper">
                            <SprkInput
                                readOnly
                                additionalClasses="center share-input"
                                autoComplete="off"
                                id="share-input"
                                type="text"
                                value={'https://www.thinklikeabot.com/game/' + this.state.gameId}
                            />
                        </SprkInputContainer>
                        <SprkButton id="button-copy" idString="button-home" additionalClasses="mt-10" onClick={() => {
                            navigator.clipboard.writeText('https://www.thinklikeabot.com/game/' + this.state.gameId);
                            this.toast("Copied to clipboard!");
                        }}>
                            Copy URL
                        </SprkButton>
                    </div>
                );
            }
            let startGameText = 'Start game';
            if (this.state.allInfosFull) {
                for (let i = 0; i < this.state.allInfosFull.length; i++) {
                    if (
                        (this.state.multiplayer && this.state.allInfosFull[i].length && this.state.allInfosFull[i][0].userId === user.sub) ||
                        !this.state.multiplayer
                    ) {
                        if (this.state.selectedType === 'classic') {
                            if (this.state.allInfosFull[i][4].score !== -1) {
                                startGameText = 'View results';
                            }
                            else if (this.state.allInfosFull[i][0].score !== -1) {
                                startGameText = 'Continue game';
                            }
                        }
                        else {
                            if (this.state.allInfosFull[i][0].streakOver) {
                                startGameText = 'View results';
                            }
                            else if (this.state.allInfosFull[i][0].score !== -1) {
                                startGameText = 'Continue game';
                            }
                        }
                        break;
                    }
                }
            }
            let isStartGameDisabled = isLoading || (this.state.gameIsStarted && !this.state.timeLimitSeconds);
            let type;
            if (this.state.multiplayer) {
                type = (
                    <div>
                        <br/>
                        <SprkHeading
                            additionalClasses="game-header"
                            element="h1"
                            variant="displayFour"
                        >
                            {this.state.daily ? 'Daily Challenge' : 'Multiplayer ' + (this.state.selectedTypeLabel ? this.state.selectedTypeLabel : '') }
                        </SprkHeading>
                    </div>
                );
            }
            else {
                type = (
                    <div>
                        <SprkHeading
                            additionalClasses="game-header"
                            element="h1"
                            variant="displayFour"
                        >
                            Single Player { this.state.selectedTypeLabel }
                        </SprkHeading>
                    </div>
                );
            }

            let settings;
            let disabledSlider = this.state.gameIsStarted;
            if (this.state.selectedType === 'classic') {
                settings = (
                    <div key={'gamesettings-' + this.state.timeLimitSetting + '-' + this.state.guessLimitSetting}>
                        <br/>
                        <SprkHeading
                            additionalClasses="game-header"
                            element="h2"
                            variant="displayFive"
                        >
                            Time limit:
                        </SprkHeading>
                        <Slider
                            ref={this.timerSliderRef}
                            disabled={disabledSlider}
                            className="mb-30"
                            marks={
                                {
                                    0: {
                                        label: '15 secs',
                                    },
                                    1: {
                                        label: '30 secs',
                                    },
                                    2: {
                                        label: '1 min',
                                    },
                                    3: {
                                        label: '2 mins',
                                    },
                                    4: {
                                        label: '3 mins',
                                    },
                                    5: {
                                        label: '4 mins',
                                    },
                                    6: {
                                        label: '5 mins',
                                    },
                                    7: {
                                        label: 'Unlimited'
                                    }
                                }
                            }
                            defaultValue={this.state.timeLimitSetting}
                            min={0}
                            max={7}
                            onAfterChange={() => {
                                this.getTimeLimit();
                            }}
                        />
                        <br/>
                        <SprkHeading
                            additionalClasses="game-header"
                            element="h2"
                            variant="displayFive"
                        >
                            Wrong guesses limit:
                        </SprkHeading>
                        <Slider
                            ref={this.guessSliderRef}
                            disabled={disabledSlider}
                            className="mb-30"
                            marks={
                                {
                                    1: {
                                        label: '1',
                                    },
                                    2: {
                                        label: '2',
                                    },
                                    3: {
                                        label: '3',
                                    },
                                    4: {
                                        label: '4',
                                    },
                                    5: {
                                        label: '5',
                                    },
                                    6: {
                                        label: 'Unlimited'
                                    }
                                }
                            }
                            defaultValue={this.state.guessLimitSetting}
                            min={1}
                            max={6}
                            onAfterChange={() => {
                                this.getGuessLimit();
                            }}
                        />
                        <br/>
                        <SprkHeading
                            additionalClasses="game-header"
                            element="h2"
                            variant="displayFive"
                        >
                            Category:
                        </SprkHeading>
                        {this.state.selectedCategoryLabel}
                    </div>
                );
            }
            else if (this.state.selectedType === 'streak') {
                settings = (
                    <div className="mt-30 mb-30">
                        How many times in a row can you guess one correct label?
                    </div>
                );
            }

            return (
                <div>
                    <SprkHeading
                        additionalClasses="game-header"
                        element="h1"
                        variant="displayTwo"
                        idString="heading-page-title"
                    >
                        Are you ready to think like a bot?
                    </SprkHeading>
                    <br/>
                    <SprkCard additionalClasses="p-30 center wide">
                        <SprkButton id="button-start" additionalClasses="mb-30" isDisabled={isStartGameDisabled} idString="button" onClick={() => {
                            if (this.state.multiplayer) {
                                this.realStartMultiplayerGame();
                            }
                            else {
                                this.realStartSinglePlayerGame();
                            }
                        }}>
                            {startGameText}
                        </SprkButton>
                        {toastContainer}
                        {type}
                        {settings}
                        {share}
                        <br/>
                        <img src={this.randomSvg2} style={{ maxWidth: '300px', margin: 'auto' }} />
                        <br/>
                    </SprkCard>
                </div>
            )
        }

        let heading = (
            <SprkHeading
                additionalClasses="game-header"
                element="h1"
                variant="displayTwo"
                idString="heading-page-title"
            >
                How do you think artificial intelligence labeled this photo?
            </SprkHeading>
        );

        let intro;
        let game;
        if (this.state.started === false) {
            if (!this.state.loading) {
                footer = (
                    <div className="footer">
                        <a href="/privacy-policy">Privacy Policy</a><br/>
                        <a href="/terms-of-service">Terms of Service</a><br/>
                  </div>
                );
            }
            heading = (
                <SprkHeading
                    additionalClasses="game-header"
                    element="h1"
                    variant="displayTwo"
                    idString="heading-page-title"
                >
                    Are you ready to think like a bot?
                </SprkHeading>
            );
            let loginMessage = '';
            if (!isLoading && !isAuthenticated) {
                loginMessage = (
                    <p>
                        Make an account to play multiplayer.
                    </p>
                )
            }

            let modalContents;
            let modalHeader = 'Coming soon';
            if (
                ['random', 'sports', 'animal', 'robot', 'nature', 'people', 'funny',].indexOf(this.state.preselectCategory) !== -1 ||
                ['classic', 'streak'].indexOf(this.state.preselectType) !== -1
            ) {
                modalHeader = 'Pick game type';
                modalContents = (
                    <div>
                        <SprkButton isDisabled={isLoading} idString="button-start" onClick={() => { this.startSinglePlayer(this.state.preselectCategory, this.state.preselectType); }}>
                            Single Player
                        </SprkButton>
                        <br/>
                        <br/>
                        {loginMessage}
                        <SprkButton isDisabled={isLoading || !isAuthenticated} idString="button-start-multi" onClick={() => { this.startMultiplayer(this.state.preselectCategory, this.state.preselectType); }}>
                            Multiplayer
                        </SprkButton>
                    </div>
                );
            }
            else {
                modalContents = 'This mode is coming soon.'
            }

            intro = (
                <div>
                    <br/>
                    <SprkCard additionalClasses="p-30 center wide category-card">
                        
                        <SprkStack splitAt="tiny">
                            <SprkStackItem additionalClasses="sprk-o-Stack__item--third@xs sprk-o-Box">
                                <div className="sports category-card-container">
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectCategory: 'random', preselectType: 'classic' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    >
                                        <img src='/tower.jpeg' className={this.state.randomHover ? 'hover' : '' }/>
                                    </a>
                                    <div 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectCategory: 'random', preselectType: 'classic' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    className="centered-overlay" onMouseEnter={ () => { this.setState({ randomHover: true }) }} onMouseLeave={ () => {this.setState({ randomHover: false }) }}>
                                        <span className="correct">✓</span><br/>Classic
                                    </div>
                                </div>
                                <p className="mt-10">
                                    Five random images. Can you guess what AI labeled each one?
                                </p>
                            </SprkStackItem>

                            <SprkStackItem additionalClasses="sprk-o-Stack__item--third@xs sprk-o-Box">
                                <div className="animals category-card-container">
                                    <a href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectCategory: 'random', preselectType: 'streak' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    >
                                        <img src='/beach.jpeg' className={this.state.streakHover ? 'hover' : '' }/>
                                    </a>
                                    <div 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectCategory: 'random', preselectType: 'streak' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    className="centered-overlay" onMouseEnter={ () => { this.setState({ streakHover: true }) }} onMouseLeave={ () => {this.setState({ streakHover: false }) }}>
                                        ⚡<br/>Streak
                                    </div>
                                </div>
                                <p className="mt-10">
                                    How many times in a row can you guess one correct label?
                                </p>
                            </SprkStackItem>

                            <SprkStackItem additionalClasses="sprk-o-Stack__item--third@xs sprk-o-Box">
                                <div className="animals category-card-container">
                                    <a href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: true, preselectCategory: 'daily', preselectType: 'classic' }, () => {
                                                this.startDailyChallenge(this.state.preselectCategory, this.state.preselectType);
                                            });
                                        }}
                                    >
                                        <img src='/giraffe.jpeg' className={this.state.dailyHover ? 'hover' : '' }/>
                                    </a>
                                    <div 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: true, preselectCategory: 'daily', preselectType: 'classic' }, () => {
                                                this.startDailyChallenge(this.state.preselectCategory, this.state.preselectType);
                                            });
                                        }}
                                    className="centered-overlay" onMouseEnter={ () => { this.setState({ dailyHover: true }) }} onMouseLeave={ () => {this.setState({ dailyHover: false }) }}>
                                        🗓️<br/>Daily Challenge
                                    </div>
                                </div>
                                <p className="mt-10">
                                    Random rules and five random images, updated daily.
                                </p>
                            </SprkStackItem>
                        </SprkStack>

                        <br/>
                        <a href="#instructions" id="button-instructions" onClick={() => { this.setState({ isInstructionsModalVisible: true }); }}>
                            How do I play?
                        </a>
                    </SprkCard>
                    <br/>
                    <SprkCard additionalClasses="p-30 center mb-30 wide">
                        <h3>Like the game?</h3>
                        <img src="/undraw_Appreciation_re_p6rl.svg" style={{ maxWidth: '300px', margin: 'auto' }}/>
                        <br/>
                        <p>
                            We depend on donations to keep the lights on.<br/>
                            It costs us money to run the server and to use the artificial intelligence API for this site.<br/>
                            If you liked playing, consider donating!
                            <br/>
                            <br/>
                        </p>

                        <a href='https://ko-fi.com/R5R06L8RJ' target='_blank'><img height='36' style={{border:'0px', height:'36px'}} src='https://cdn.ko-fi.com/cdn/kofi2.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com' /></a>
                        <br/>
                         <form action="https://www.paypal.com/donate" method="post" target="_top">
                            <input type="hidden" name="business" value="VZ89D2KPXRTEG" />
                            <input type="hidden" name="no_recurring" value="0" />
                            <input type="hidden" name="item_name" value="www.thinklikeabot.com" />
                            <input type="hidden" name="currency_code" value="USD" />
                            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                        </form>
                    </SprkCard>

                    <SprkModal
                        title={modalHeader}
                        isVisible={this.state.isCategoryModalVisible}
                        confirmText=""
                        cancelText="Cancel" 
                        id="category-modal"
                        cancelClick={() => {
                            this.setState({
                                isCategoryModalVisible: false,
                            });
                        }}
                    >
                        {modalContents}
                    </SprkModal>

                    <SprkModal
                        title="Instructions"
                        isVisible={this.state.isInstructionsModalVisible}
                        confirmText=""
                        cancelText="OK" 
                        id="instructions-modal"
                        cancelClick={() => {
                            this.setState({
                                isInstructionsModalVisible: false,
                            });
                        }}
                    >
                        You'll be shown some random images. <br/>
                        Using single words or short phrases, describe how you think an <a target="_blank" href="https://aws.amazon.com/rekognition/">artificial intelligence system</a> labeled each image.<br/>
                        <strong>If you're in Unlimited guesses mode, guess as many times as you want: there's no penalty for wrong answers.</strong><br/>
                        The plural and singular versions of labels will count as the same label.<br/>
                        When you're done guessing, click the "View labels" button to see the answers.<br/>
                        Keep playing as long as you like!
                    </SprkModal>

                    <SprkCard additionalClasses="category-card p-30 center mb-30 wide">
                        <h3>Categories</h3>
                    
                        <SprkStack splitAt="tiny">
                            <SprkStackItem additionalClasses="sprk-o-Stack__item--third@xs sprk-o-Box">
                                <div className="sports category-card-container">
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectType: 'classic', preselectCategory: 'sports' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    >
                                        <img src='/sports.jpeg' className={this.state.sportsHover ? 'hover' : '' }/>
                                    </a>
                                    <div 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectType: 'classic', preselectCategory: 'sports' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    className="centered-overlay" onMouseEnter={ () => { this.setState({ sportsHover: true }) }} onMouseLeave={ () => {this.setState({ sportsHover: false }) }}>
                                        Sports
                                    </div>
                                </div>
                            </SprkStackItem>

                            <SprkStackItem additionalClasses="sprk-o-Stack__item--third@xs sprk-o-Box">
                                <div className="animals category-card-container">
                                    <a href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectType: 'classic', preselectCategory: 'animal' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    >
                                        <img src='/animals.jpeg' className={this.state.animalsHover ? 'hover' : '' }/>
                                    </a>
                                    <div 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectType: 'classic', preselectCategory: 'animal' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    className="centered-overlay" onMouseEnter={ () => { this.setState({ animalsHover: true }) }} onMouseLeave={ () => {this.setState({ animalsHover: false }) }}>
                                        Animals
                                    </div>
                                </div>
                            </SprkStackItem>

                            <SprkStackItem additionalClasses="sprk-o-Stack__item--third@xs sprk-o-Box">
                                <div className="robots category-card-container">
                                    <a href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectType: 'classic', preselectCategory: 'robot' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    >
                                        <img src='/robots.jpeg' className={this.state.robotsHover ? 'hover' : '' }/>
                                    </a>
                                    <div 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectType: 'classic', preselectCategory: 'robot' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    className="centered-overlay" onMouseEnter={ () => { this.setState({ robotsHover: true }) }} onMouseLeave={ () => {this.setState({ robotsHover: false }) }}>
                                        Robots
                                    </div>
                                </div>
                            </SprkStackItem>
                        </SprkStack>

                        <SprkStack splitAt="tiny">
                            <SprkStackItem additionalClasses="sprk-o-Stack__item--third@xs sprk-o-Box">
                                <div className="nature category-card-container">
                                    <a href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectType: 'classic', preselectCategory: 'nature' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    >
                                        <img src='/nature.jpeg' className={this.state.natureHover ? 'hover' : '' }/>
                                    </a>
                                    <div 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectType: 'classic', preselectCategory: 'nature' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    className="centered-overlay" onMouseEnter={ () => { this.setState({ natureHover: true }) }} onMouseLeave={ () => {this.setState({ natureHover: false }) }}>
                                        Nature
                                    </div>
                                </div>
                            </SprkStackItem>
                            <SprkStackItem additionalClasses="sprk-o-Stack__item--third@xs sprk-o-Box">
                                <div className="people category-card-container">
                                    <a href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectType: 'classic', preselectCategory: 'people' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    >
                                        <img src='/people.jpeg' className={this.state.peopleHover ? 'hover' : '' }/>
                                    </a>
                                    <div 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectType: 'classic', preselectCategory: 'people' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    className="centered-overlay" onMouseEnter={ () => { this.setState({ peopleHover: true }) }} onMouseLeave={ () => {this.setState({ peopleHover: false }) }}>
                                        People
                                    </div>
                                </div>
                            </SprkStackItem>

                            <SprkStackItem additionalClasses="sprk-o-Stack__item--third@xs sprk-o-Box">
                                <div className="funny category-card-container">
                                    <a href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectType: 'classic', preselectCategory: 'funny' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    >
                                        <img src='/funny.jpeg' className={this.state.funnyHover ? 'hover' : '' }/>
                                    </a>
                                    <div
                                         onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ daily: false, preselectType: 'classic', preselectCategory: 'funny' });
                                            this.setOpenCategoryModal(true);
                                        }}
                                    className="centered-overlay" onMouseEnter={ () => { this.setState({ funnyHover: true }) }} onMouseLeave={ () => {this.setState({ funnyHover: false }) }}>
                                        Funny
                                    </div>
                                </div>
                            </SprkStackItem>
                        </SprkStack>

                    </SprkCard>


                </div>
            );
            if (this.state.loading) {
                intro = (
                    <SprkButton
                    variant="tertiary"
                    idString="button-9"
                    analyticsString="button-9-analytics"
                    isSpinning
                    >
                        <SprkSpinner variant="secondary" />
                    </SprkButton>
                );
            }
        }
        else {
            let guesses = this.getGuessListLis(this.state.guesses, 'mine');

            let controls;
            let nextButton;
            let results;
            let guessList;
            if (this.state.showingResults) {
                let resultItems = [];
                for (let i = 0; i < this.state.labels.Labels.length; i++) {
                    let label = this.state.labels.Labels[i].Name.toLowerCase().trim();
                    let confidence = this.state.labels.Labels[i].Confidence.toFixed(2);
                    let guessedRight;
                    let contained = false;
                    for (let j = 0; j < this.state.matchingGuesses.length; j++) {
                        if (this.state.matchingGuesses[j].toLowerCase().trim() === label) {
                            contained = true;
                            break;
                        }
                    }

                    if (!contained) {
                        /*guessedRight = (
                            <span className="incorrect">x</span>
                        );
                        */
                       guessedRight = '';
                    }
                    else {
                        guessedRight = (
                            <span className="correct">✓</span>
                        );
                    }
                    resultItems.push(<SprkListItem key={'list-item2-' + i} idString={"list-item-" + i}>{label} ({confidence}%) {guessedRight}</SprkListItem>);
                }

                nextButton = (
                    <SprkButton idString="button-next" onClick={() => {
                        this.next()
                    }}>
                        {(this.state.selectedType === 'classic' && this.state.currentImage < 4) || (this.state.selectedType === 'streak' && !this.state.streakOver) ? 'Next image →' : 'View Results →'}
                    </SprkButton>
                );

                results =  (
                    <SprkCard idString="default" additionalClasses="image-card">
                        <SprkStack
                            additionalClasses="sprk-o-Stack__item sprk-c-Card__content"
                            itemSpacing="medium"
                        >
                            <SprkStackItem>
                                {nextButton}
                                <br/>
                                <SprkHeading element="h2" variant="displayFour" id="actual">
                                    Actual labels (Confidence %):
                                </SprkHeading>
                            </SprkStackItem>
                            <SprkList element="ul" idString="unordered-list-1">
                                {resultItems}
                            </SprkList>
                        </SprkStack>
                    </SprkCard>
                );
            }
            else {
                let view;
                if (this.state.selectedType === 'classic') {
                    view = (
                        <SprkButton id="button-show" idString="button-1" onClick={() => {
                            this.showResults()
                        }}>
                            View labels
                        </SprkButton>
                    );
                }
                controls = (
                    <div>
                        <SprkInputContainer>
                            <SprkInput
                                autoFocus
                                autoComplete="off"
                                id="guess-input"
                                type="text"
                                placeholder="Enter your guesses here"
                                onKeyDown={this._handleKeyDown}
                                forwardedRef={this.guessInputRef}
                            />
                        </SprkInputContainer>
                        {view}
                        <SprkButton id="button-guess" additionalClasses="ml-30" idString="button-guess" onClick={() => {
                            this.handleGuess()
                        }}>
                            Guess
                        </SprkButton>
                        <br/>
                    </div>
                );
            }
            if (this.state.guesses.length) {
                let br;
                if (!this.state.showingResults) {
                    br = <br/>;
                }
                guessList = (
                    <div>
                        {br}
                        <SprkStackItem>
                            <SprkHeading element="h3" variant="displayFive">
                                Your guesses:
                            </SprkHeading>
                        </SprkStackItem>
                        <SprkList element="ul" idString="unordered-list-1">
                            {guesses}
                        </SprkList>
                    </div>
                );
            }

            let multiResults;
            if (this.state.multiplayer && this.state.showingResults) {
                let multiResultPartsHeaders = [];
                let multiResultParts = [];
                let allInfos = this.state.allInfos ? this.state.allInfos || [] : [];
                for (let i = 0; i < allInfos.length; i++) {
                    let guessListLis = this.getGuessListLis(allInfos[i].guesses, i);
                    if (allInfos[i].score === -1) {
                        guessListLis = <SprkListItem>Not played yet</SprkListItem>;
                    }
                    let props = {};
                    if (i === 0) {
                        props.isDefaultActive = true;
                    }
                    multiResultPartsHeaders.push(
                        <Tab>{allInfos[i].userName}</Tab>
                    );
                    /*
                    multiResultParts.push(
                        <SprkTabsPanel
                            key={"panel-" + i}
                            tabBtnChildren={allInfos[i].userId}
                            tabBtnDataId={"tab-" + i}

                            {...props}
                        >
                            <SprkList element="ul" idString="unordered-list-1">
                                {guessListLis}
                            </SprkList>
                        </SprkTabsPanel>
                    );
                    */
                   multiResultParts.push(
                       <TabPanel>
                           <SprkList element="ul">
                                {guessListLis}
                            </SprkList>
                       </TabPanel>
                   )
                }
                multiResults = (
                    <div>
                        <SprkCard additionalClasses="center mt-30 mb-30">
                            <SprkHeading additionalClasses="mt-30 mb-10" element="h3" variant="displayFive">
                                All player guesses:
                            </SprkHeading>
                            <Tabs className="mb-30">
                                <TabList>
                                    {multiResultPartsHeaders}
                                </TabList>
                                {multiResultParts}
                            </Tabs>
                        </SprkCard>
                    </div>
                );
            }

            let timer;
            if (this.state.timeLeftSeconds !== 0 && this.state.timeLeftSeconds !== -1) {
                timer = (
                    <div>
                        <br/>
                        <SprkHeading element="h2" variant="displayFive">
                            {this.getTimeLeftString()}
                        </SprkHeading>
                        <ProgressBar
                            completed={this.getTimeLeftPercentage()}
                            bgColor="#603aa1"
                            isLabelVisible={false}
                            height="10px"
                        />
                    </div>
                );
            }

            let guessesLeftBlock;
            if (this.state.guessLimitSetting && this.state.guessLimitSetting !== 6 && this.state.guessesLeft !== 0) {
                guessesLeftBlock = (
                    <div>
                        <br/>
                        <SprkHeading element="h2" variant="displayFive">
                            {this.getGuessesLeftStr()}
                        </SprkHeading>
                        <ProgressBar
                            completed={this.getGuessesLeftPercentage()}
                            bgColor="#603aa1"
                            isLabelVisible={false}
                            height="10px"
                        />
                    </div>
                );
            }

            let info;
            if (isMobile) {
                info = (
                    <div>
                        <SprkStackItem additionalClasses="mb-30">
                            <SprkHeading element="h2" variant="displayFour">
                                {this.getLabelsLeftCount()} label{this.getLabelsLeftCount() !== 1 ? 's' : ''} left to guess
                            </SprkHeading>
                            <ProgressBar
                                completed={100 * (this.getCorrectCount() / this.getLabelsCount())}
                                bgColor="#603aa1"
                                isLabelVisible={false}
                                height="10px"
                            />
                            {timer}
                            {guessesLeftBlock}
                        </SprkStackItem>
                        <div>    
                            <p><strong>Type:</strong> {this.state.daily ? 'Daily Challenge' : this.state.multiplayer ? 'Multiplayer ' + (this.state.selectedTypeLabel ? this.state.selectedTypeLabel : '') : 'Single Player ' + (this.state.selectedTypeLabel ? this.state.selectedTypeLabel : '')}</p>
                            <p><strong>Category:</strong> {getCategoryLabel(this.state.selectedCategory)}</p>
                            <p><strong>Round:</strong> {this.state.selectedType === 'classic' ? (this.state.currentImage+1) + '/5' : (this.state.currentImage+1)}</p>
                            <p><strong>Score:</strong> {this.state.score}</p>
                        </div>
                    </div>
                );
            }
            else {
                info = (
                    <div>
                        <div>    
                            <p><strong>Type:</strong> {this.state.daily ? 'Daily Challenge' : this.state.multiplayer ? 'Multiplayer ' + (this.state.selectedTypeLabel ? this.state.selectedTypeLabel : '') : 'Single Player ' + (this.state.selectedTypeLabel ? this.state.selectedTypeLabel : '')}</p>
                            <p><strong>Category:</strong> {getCategoryLabel(this.state.selectedCategory)}</p>
                            <p><strong>Round:</strong> {this.state.selectedType === 'classic' ? (this.state.currentImage+1) + '/5' : (this.state.currentImage+1)}</p>
                            <p><strong>Score:</strong> {this.state.score}</p>
                        </div>
                        <SprkStackItem>
                            <SprkHeading element="h2" variant="displayFour">
                                {this.getLabelsLeftCount()} label{this.getLabelsLeftCount() !== 1 ? 's' : ''} left to guess
                            </SprkHeading>
                            <ProgressBar
                                completed={100 * (this.getCorrectCount() / this.getLabelsCount())}
                                bgColor="#603aa1"
                                isLabelVisible={false}
                                height="10px"
                            />
                            {timer}
                            {guessesLeftBlock}
                        </SprkStackItem>
                    </div>
                );
            }

            game = (
                <div>
                    <SprkStack
                        additionalClasses="sprk-o-Stack__item--half@xs"
                        itemSpacing="tiny"
                        splitAt="tiny"
                    >
                        <SprkCard idString="default" additionalClasses="image-card">
                            <SprkStack
                                additionalClasses="sprk-o-Stack__item sprk-c-Card__content"
                                itemSpacing="medium"
                            >
                                    <img src={this.state.imageUrl} />
                            </SprkStack>
                        </SprkCard>
                        <SprkCard idString="default" additionalClasses="image-card">
                            <SprkStack
                                additionalClasses="sprk-o-Stack__item sprk-c-Card__content"
                                itemSpacing="medium"
                            >
                                <MobileView>
                                    {controls}
                                    {info}
                                    {guessList}
                                </MobileView>
                                <BrowserView>
                                    {info}
                                    <div className="mt-30">
                                        {controls}
                                    </div>
                                    {guessList}
                                </BrowserView>
                            </SprkStack>
                        </SprkCard>

                        {results}
                    </SprkStack>
                    {multiResults}
                </div>
            );
        }

        if (this.state.finished) {
            heading = (
                <SprkHeading
                    additionalClasses="game-header"
                    element="h1"
                    variant="displayTwo"
                    idString="heading-page-title"
                >
                    Results
                </SprkHeading>
            );
            let columns = [
                {
                    name: 'id',
                    header: 'ID',
                },
                {
                    name: 'player',
                    header: 'Player',
                },
                {
                    name: 'round1',
                    header: 'Round 1',
                },
                {
                    name: 'round2',
                    header: 'Round 2',
                },
                {
                    name: 'round3',
                    header: 'Round 3',
                },
                {
                    name: 'round4',
                    header: 'Round 4',
                },
                {
                    name: 'round5',
                    header: 'Round 5',
                },
                {
                    name: 'total',
                    header: 'Total',
                },
            ];
            if (this.state.selectedType === 'streak') {
                columns = [
                    {
                        name: 'id',
                        header: 'ID',
                    },
                    {
                        name: 'player',
                        header: 'Player',
                    },
                    {
                        name: 'total',
                        header: 'Total',
                    }
                ]
            }
            let rows = [];
            if (this.state.multiplayer && this.state.allInfosFull && this.state.allInfosFull.length) {
                let sorted = this.state.allInfosFull;
                sorted.sort((x, y) => {
                    let xScore = 0;
                    let yScore = 0;
                    if (x.length && x[0].hasOwnProperty('totalScore')) {
                        xScore = x[0].totalScore;
                    }
                    if (y.length && y[0].hasOwnProperty('totalScore')) {
                        yScore = y[0].totalScore;
                    }
                    if (xScore === yScore) {
                        return 0;
                    }
                    if (xScore > yScore) {
                        return -1;
                    }
                    if (xScore < yScore) {
                        return 1;
                    }
                });
                for (let i = 0; i < sorted.length; i++) {
                    let scores = [0, 0, 0, 0, 0];
                    for (let j = 0; j < 5; j++) {
                        if (sorted[i].length - 1 >= j && sorted[i][j].hasOwnProperty('score') && sorted[i][j].score !== -1) {
                            scores[j] = sorted[i][j].score;
                        }
                        else {
                            scores[j] = 'N/A';
                        }
                    }
                    let totalScore = 0;
                    if (sorted[i].length && sorted[i][0].hasOwnProperty('totalScore')) {
                        totalScore = sorted[i][0].totalScore;
                    }
                    if (totalScore === -1) {
                        totalScore = 0;
                    }
                    if (!sorted[i][0].userName) {
                        continue;
                    }
                    rows.push({
                        id: sorted[i][0].userId,
                        player: sorted[i][0].userName,
                        round1: scores[0],
                        round2: scores[1],
                        round3: scores[2],
                        round4: scores[3],
                        round5: scores[4],
                        total: totalScore,
                    });
                }
            }
            else {
                rows.push({
                    player: 'You',
                    round1: this.state.roundScores[0],
                    round2: this.state.roundScores[1],
                    round3: this.state.roundScores[2],
                    round4: this.state.roundScores[3],
                    round5: this.state.roundScores[4],
                    total: this.state.score,
                });
            }

            let share;
            if (this.state.multiplayer) {
                share = (
                    <div>
                        <br/>
                        <br/>
                        <SprkCard className="center wide">
                            <p>
                                Share this URL to play against your friends:
                            </p>
                            <SprkInputContainer additionalClasses="share-input-wrapper">
                                <SprkInput
                                    readOnly
                                    additionalClasses="center share-input"
                                    autoComplete="off"
                                    id="share-input"
                                    type="text"
                                    value={'https://www.thinklikeabot.com/game/' + this.state.gameId}
                                />
                            </SprkInputContainer>
                            {toastContainer}
                            <SprkButton id="button-copy" idString="button-home" additionalClasses="mt-10" onClick={() => {
                                navigator.clipboard.writeText('https://www.thinklikeabot.com/game/' + this.state.gameId);
                                this.toast('Copied to clipboard!');
                            }}>
                                Copy URL
                            </SprkButton>
                        </SprkCard>
                    </div>
                );
            }

            let accordionItems = [];
            for (let i = 0; i < this.state.images.length; i++) {
                let multiResults;   
                let multiResultPartsHeaders = [];
                let multiResultParts = [];

                let guessListLis = this.getGuessListLis(this.state.images[i].labels.Labels.map(x => x.Name), 'actual', true);
                multiResultPartsHeaders.push(
                    <Tab><strong>Actual Labels</strong></Tab>
                );
                multiResultParts.push(
                    <TabPanel>
                        <SprkList element="ul">
                            {guessListLis}
                        </SprkList>
                    </TabPanel>
                );

                let allInfosFull = this.state.allInfosFull ? this.state.allInfosFull || [] : [];
                for (let j = 0; j < allInfosFull.length; j++) {
                    let allInfos = allInfosFull[j];
                    let thisInfo = allInfos[i];
                    let guessListLis = this.getGuessListLis(thisInfo.guesses, i, false, this.state.images[i].labels);
                    if (thisInfo.score === -1) {
                        guessListLis = <SprkListItem>Not played yet</SprkListItem>;
                    }
                    let props = {};
                    if (i === 0) {
                        props.isDefaultActive = true;
                    }
                    multiResultPartsHeaders.push(
                        <Tab>{thisInfo.userName ? thisInfo.userName : 'You'}</Tab>
                    );
                    multiResultParts.push(
                        <TabPanel>
                            <SprkList element="ul">
                                {guessListLis}
                            </SprkList>
                        </TabPanel>
                    );
                }

                multiResults = (
                    <Tabs className="">
                        <TabList>
                            {multiResultPartsHeaders}
                        </TabList>
                        {multiResultParts}
                    </Tabs>
                );

                let accordionItem = (
                    <SprkAccordionItem
                        heading={"Round " + (i+1)}
                        contentAdditionalClasses="sprk-o-Stack sprk-o-Stack--medium"
                    >
                        <SprkStack splitAt="tiny">
                            <SprkStackItem additionalClasses="sprk-o-Stack__item--half@xs sprk-o-Box">
                                <img src={this.state.images[i].imageUrl} style={{ maxWidth: '300px' }}/>
                            </SprkStackItem>

                            <SprkStackItem additionalClasses="sprk-o-Stack__item--half@xs sprk-o-Box">
                               {multiResults}
                            </SprkStackItem>
                        </SprkStack>
                    </SprkAccordionItem>
                );
                accordionItems.push(accordionItem);
            }
            let accordion = (
                <div>
                    <br/>
                    <br/>
                    <SprkAccordion>
                        {accordionItems}
                    </SprkAccordion>
                </div>
            );
            if (this.state.selectedType === 'streak') {
                accordion = '';
            }

            let timeLimitSecs = -1;
            if (this.state.timeLimitSetting === 0) {
                timeLimitSecs = 15;
            }
            else if (this.state.timeLimitSetting === 1) {
                timeLimitSecs = 30;
            }
            else if (this.state.timeLimitSetting === 2) {
                timeLimitSecs = 60;
            }
            else if (this.state.timeLimitSetting === 3) {
                timeLimitSecs = 120;
            }
            else if (this.state.timeLimitSetting === 4) {
                timeLimitSecs = 180;
            }
            else if (this.state.timeLimitSetting === 5) {
                timeLimitSecs = 240;
            }
            else if (this.state.timeLimitSetting === 6) {
                timeLimitSecs = 300;
            }
            else if (this.state.timeLimitSetting === 7) {
                timeLimitSecs = -1;
            }
            game = (
                <div>
                    <SprkCard id="result-card" idString="default" additionalClasses="image-card">
                        <p><strong>Type:</strong> {this.state.daily ? 'Daily Challenge' : this.state.multiplayer ? 'Multiplayer ' + (this.state.selectedTypeLabel ? this.state.selectedTypeLabel : '') : 'Single Player ' + (this.state.selectedTypeLabel ? this.state.selectedTypeLabel : '')}</p>
                        <p><strong>Category:</strong> {getCategoryLabel(this.state.selectedCategory)}</p>
                        <p><strong>Time Limit:</strong> {timeLimitSecs === -1 ? 'Unlimited' : getTimeStr(timeLimitSecs)}</p>
                        <p><strong>Guess Limit:</strong> {this.state.guessLimitSetting === 6 ? 'Unlimited' : this.state.guessLimitSetting}</p>
                        <br/>
                        <SprkTable
                            additionalTableClasses="score-table sprk-b-Table--spacing-medium"
                            idString="table-1"
                            columns={columns}
                            rows={rows}
                        />
                        {share}
                        {accordion}
                        <br/>
                        <br/>
                        <img src={this.randomSvg3} style={{ maxWidth: '300px', margin: 'auto' }} />
                        <br/>
                        <SprkButton id="button-play-again" idString="button-play-again" onClick={() => {
                            this.playAgain();
                        }}>
                            Play Again
                        </SprkButton>
                        <SprkButton id="button-home" idString="button-home" onClick={() => {
                            window.location.href = '/';
                        }}>
                            Back to Home
                        </SprkButton>
                    </SprkCard>
                </div>
            );
        }

        if (this.state.error) {
            heading = (
                <SprkHeading
                    additionalClasses="game-header"
                    element="h1"
                    variant="displayTwo"
                    idString="heading-page-title"
                >
                    Uh-oh, something went wrong.
                </SprkHeading>
            );
            intro = (
                <p>
                    <br/>
                    We're aware of the problem and are working on fixing it.
                </p>
            )
        }

        return (
            <div className="game">
                {heading}
                {intro}
                {game}
                {footer}
            </div>
        );
    }
}

export default withAuth0(Game);
