import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutLink = () => {
  const { logout } = useAuth0();

  return (
    <a
      href="#logout"
      className="logout-link sprk-b-Link sprk-b-Link--simple sprk-c-Masthead__link"
      onClick={(e) => {
        e.preventDefault();
        logout({ returnTo: window.location.origin });
      }}>
      Log Out
    </a>
  );
};

export default LogoutLink;