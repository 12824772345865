import './App.scss';
import React from 'react';
import Masthead from './components/Masthead';
import Game from './components/Game';
import About from './components/About';
import Settings from './components/Settings';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import Auth0Token from './components/Auth0Token';
import { withAuth0 } from '@auth0/auth0-react';
import { setCookie, getCookie } from "./components/Helpers";
import {
  SprkButton,
  SprkSpinner,
  SprkHeading,
} from '@sparkdesignsystem/spark-react';
import CookieConsent from "react-cookie-consent";
import PrivacyPolicyInner from './components/PrivacyPolicyInner';
import TermsOfServiceInner from './components/TermsOfServiceInner';
import MyGames from './components/MyGames';
import Leaderboards from './components/Leaderboards';
class App extends React.Component {
  render = () => {
    let token;
    const { isAuthenticated, isLoading } = this.props.auth0;
    if (!isLoading && isAuthenticated) {
      token = <Auth0Token/>;
    }
    else {
      token = (
        <div id="token" style={{ display: 'none' }}>null</div>
      );
    }

    const path = getCookie('login-from');
    if (path && !isLoading) {
        setCookie('login-from', '', 1);
        window.location.pathname = path;
        return (
          <div className="App">
            <Masthead/>
            <SprkButton
                variant="tertiary"
                idString="button-9"
                analyticsString="button-9-analytics"
                isSpinning
            >
                <SprkSpinner variant="secondary" />
            </SprkButton>
          </div>
        );
      }

    return (
      <div className="App">
        <Masthead/>
        <Router>
          <Switch>
            <Route exact path='/'>
              <Game/>
            </Route>
            <Route path="/game/:id" component={Game} />
            <Route path="/singleplayer_game/:id" component={Game} />
            <Route exact path='/about'>
              <About/>
            </Route>
            <Route exact path='/settings'>
              <Settings/>
            </Route>
            <Route exact path='/my-games'>
              <MyGames/>
            </Route>
            <Route exact path='/leaderboards'>
              <Leaderboards/>
            </Route>
            <Route exact path='/privacy-policy'>
              <PrivacyPolicyInner/>
            </Route>
            <Route exact path='/terms-of-service'>
              <TermsOfServiceInner/>
            </Route>
            <Route path='*'>
              <SprkHeading
                  additionalClasses="game-header"
                  element="h1"
                  variant="displayTwo"
                  idString="heading-page-title"
              >
                  Uh-oh.
              </SprkHeading>
              <br/>
              <img src='/undraw_page_not_found_su7k.svg' style={{ maxWidth: '300px'}} />
              <br/>
              <br/>
              That page was not found.<br/><br/>
              <SprkButton id="button-home" idString="button-home" onClick={() => {
                  window.location.href = '/';
              }}>
                  Back to Home
              </SprkButton>
            </Route>
          </Switch>
          {token}
          <CookieConsent
            location="bottom"
            style={{ background: '#ffffff', color: 'black' }}
            buttonStyle={{ backgroundColor: '#603aa1', color: '#ffffff', fontSize: "16px" }}
          >
            This website uses cookies to enhance the user experience.
          </CookieConsent>
        </Router>

      </div>
    );
  }

}

export default withAuth0(App);
