import React from 'react';
import {
    SprkButton,
    SprkSpinner,
    SprkCard,
    SprkHeading,
} from '@sparkdesignsystem/spark-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import DataTable from 'react-data-table-component';


class Leaderboards extends React.Component {
    state = {
        loading: true,
        leaderboards: null,
    }

    constructor(props) {
        super(props);

        this.apiUrl = process.env.REACT_APP_API_URL;
    }

    componentDidMount = () => {
        this.getLeaderboards((data) => {
            if (!data) {
                return;
            }

            this.setState({
                loading: false,
                leaderboards: data,
            });
        });
    }

    getLeaderboards = (callback) => {
        fetch(this.apiUrl + '/leaderboards', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            console.log(err);
            if (typeof callback === 'function') {
                callback(null);
            }
        });   
    }

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <SprkHeading additionalClasses="mt-30 mb-10" element="h2" variant="displayTwo" idString="heading-two">
                        Leaderboards
                    </SprkHeading>
                    <SprkButton
                        variant="tertiary"
                        idString="button-9"
                        analyticsString="button-9-analytics"
                        isSpinning
                    >
                        <SprkSpinner variant="secondary" />
                    </SprkButton>
                </div>
            );
        }

        const classicColumns = [
            {
                name: 'Position',
                selector: row => row.position,
            },
            {
                name: 'User',
                selector: row => row.userName,
            },
            {
                name: 'Score',
                selector: row => row.totalScore,
            },
        ];

        const streakColumns = [
            {
                name: 'Position',
                selector: row => row.position,
            },
            {
                name: 'User',
                selector: row => row.userName,
            },
            {
                name: 'Score',
                selector: row => row.totalScore,
            },
        ];

        const dailyColumns = [
            {
                name: 'Position',
                selector: row => row.position,
            },
            {
                name: 'User',
                selector: row => row.userName,
            },
            {
                name: 'Score',
                selector: row => row.totalScore,
            },
        ];

        return (
            <div>
                <SprkHeading additionalClasses="mt-30 mb-10" element="h2" variant="displayTwo" idString="heading-two">
                    Leaderboards
                </SprkHeading>
                <SprkCard additionalClasses="center mt-30 mb-30 wide">
                    <Tabs className="mb-30">
                        <TabList>
                            <Tab>Classic</Tab>
                            <Tab>Streak</Tab>
                            <Tab>Daily Challenge</Tab>
                            </TabList>
                        <TabPanel>
                            <DataTable
                                columns={classicColumns}
                                data={this.state.leaderboards ? this.state.leaderboards.classic : []}
                            />
                        </TabPanel>

                        <TabPanel>
                            <DataTable
                                columns={streakColumns}
                                data={this.state.leaderboards ? this.state.leaderboards.streak : []}
                            />
                        </TabPanel>

                        <TabPanel>
                            <DataTable
                                columns={dailyColumns}
                                data={this.state.leaderboards ? this.state.leaderboards.daily : []}
                            />
                        </TabPanel>
                    </Tabs>
                </SprkCard>
            </div>
        );
    }
}

export default Leaderboards;