import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const SignupLink = (props) => {
  const { loginWithRedirect } = useAuth0();

  const text = props.textOverride ? props.textOverride : 'Sign up';
  return <a
    href="#login"
    className={'login-link ' + props.className}
    onClick={(e) => {
      e.preventDefault();
      setTimeout(() => {
        loginWithRedirect({
          screen_hint: 'signup'
        });
      }, 0);
    }}>
      {text}
    </a>;
};

export default SignupLink;