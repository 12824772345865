import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Auth0Token = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const [token, setToken] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        let accessToken;
        const getTokenAndTryAgain = async () => {
            await getAccessTokenWithPopup({
                audience: 'https://www.thinklikeabot.com/api',
            });
            window.location.reload();
        };
        try {
            accessToken = await getAccessTokenSilently({
                audience: 'https://www.thinklikeabot.com/api',
            });
        } catch (e) {
            console.log(e);
            getTokenAndTryAgain();
            console.log(accessToken);
        }
        setToken(accessToken);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently, getAccessTokenWithPopup]);

  return (
    <div id="token" style={{ display: 'none' }}>{token}</div>
  );
};

export default Auth0Token;