import React from 'react';
import { SprkMasthead, Link, SprkLink } from '@sparkdesignsystem/spark-react';
import LogoutLink from './LogoutLink';
import LoginLink from './LoginLink';
import SignupLink from './SignupLink';
import { withAuth0 } from '@auth0/auth0-react';
class Masthead extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { isAuthenticated, isLoading } = this.props.auth0;

        let links = [
            {
                element: Link,
                text: "Home",
                to: "/",
                href: "/",
              },
              {
                element: Link,
                text: "About",
                to: "/about",
                href: "/about",
              },
        ];  
        if (!isLoading && isAuthenticated) {
            links.push({
                element: Link,
                text: "My Games",
                to: "/my-games",
                href: "/my-games",
            });
            links.push({
                element: Link,
                text: "Leaderboards",
                to: "/leaderboards",
                href: "/leaderboards",
            });
            links.push({
                element: Link,
                text: "Settings",
                to: "/settings",
                href: "/settings",
            });
            links.push(
                {
                    element: LogoutLink,
                    text: "Logout",
                    to: "#logout",
                    href: "#logout",
                }
            );
        }
        else if (!isLoading && !isAuthenticated) {
            links.push({
                element: Link,
                text: "Leaderboards",
                to: "/leaderboards",
                href: "/leaderboards",
            });
            links.push(
                {
                    element: LoginLink,
                    text: "Login",
                    to: "#login",
                    href: "#login",
                }
            );
            links.push(
                {
                    element: SignupLink,
                    text: "Login",
                    to: "#login",
                    href: "#login",
                }
            );
        }

        return (
            <SprkMasthead
                key={JSON.stringify(this.props.auth0)}
                additionalClasses="sprk-docs-Masthead"
                littleNavLinks={links}
                narrowNavLinks={links}
                siteLogo={
                    <div className="logo-title">
                        🤖 <span className="logo-title-1">think</span> <span className="logo-title-2">like</span> <span>a</span> <span className="logo-title-3">bot</span>
                    </div>
                }
                logoLink="/"
            />
        );
    }
}

export default withAuth0(Masthead);
