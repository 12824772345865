import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import {
    SprkCard,
    SprkInput,
    SprkInputContainer,
    SprkButton,
    SprkSpinner,
    SprkHeading,
    SprkDictionary,
    SprkPagination,
} from '@sparkdesignsystem/spark-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginButton from './LoginButton';
import { getCategoryLabel, getCategory, secondsToTime, getTimeStr } from './Helpers';

var qs = require('qs');
const moment = require('moment');

class Settings extends React.Component {
    state = {
        loading: true,
        games: null,
        offset: 0,
        limit: 10,
        currentPage: 1,
        maxPages: 1,
    }

    constructor(props) {
        super(props);
        this.apiUrl = process.env.REACT_APP_API_URL;
    }
    
    componentDidMount = () => {
        let page = 1;
        if (window.location && window.location.search) {
            page = Number.parseInt(qs.parse(window.location.search, { ignoreQueryPrefix: true }).page);
            if (isNaN(page) || page < 1) {
                page = 1;
            }
        }
        this.setState({
            loading: true,
            currentPage: page,
            offset: this.state.limit * (page-1),
        }, () => {
            this.getGames(this.state.offset, this.state.limit, (data) => {
                if (!data) {
                    return;
                }

                _this.setState({
                    loading: false,
                    games: data.games,
                    maxPages: Math.ceil(data.totalCount / this.state.limit),
                });
            });
            let _this = this;
            let intervalId = setInterval(() => {
                if (_this.state.games) {
                    clearInterval(intervalId);
                    return;
                }
                _this.getGames(_this.state.offset, _this.state.limit, (data) => {
                    if (!data) {
                        return;
                    }

                    _this.setState({
                        loading: false,
                        games: data.games,
                        maxPages: Math.ceil(data.totalCount / this.state.limit),
                    });
                })
            }, 500);
        });
    }

    setPage = (page) => {
        this.setState({
            loading: true,
            currentPage: page,
            offset: this.state.limit * (page-1),
        }, () => {
            this.getGames(this.state.offset, this.state.limit, (data) => {
                if (!data) {
                    return;
                }

                this.setState({
                    loading: false,
                    games: data.games,
                    maxPages: Math.ceil(data.totalCount / this.state.limit),
                });
            });
        });
    }

    getToken = () => {
        let token = document.getElementById('token');
        return token ? token.innerHTML ? token.innerHTML.trim() : '' : '';
    }

    getGames = (offset, limit, callback) => {
        if (!offset) {
            offset = 0;
        }
        if (!limit) {
            limit = 10;
        }

        const token = this.getToken();
        if (!token) {
            if (typeof callback === 'function') {
                return callback(null);
            }
            return null;
        }

        fetch(this.apiUrl + '/my_games?offset=' + offset + '&limit=' + limit, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            if (typeof callback === 'function') {
                callback(data);
            }
        }).catch(err => {
            console.log(err);
            if (typeof callback === 'function') {
                callback(null);
            }
        });   
    }

    render = () => {
        const { isAuthenticated, isLoading } = this.props.auth0;

        if (!isLoading && !isAuthenticated) {
            return (
                <div>
                    <SprkHeading
                        additionalClasses="game-header"
                        element="h1"
                        variant="displayTwo"
                        idString="heading-page-title"
                    >
                        Please login.
                    </SprkHeading>
                    <br/>
                    Login to view your games.<br/><br/>
                    <LoginButton/>
                    <LoginButton className="ml-30" textOverride="Sign up" signUp={true} />
                </div>
            );
        }

        if (this.state.loading) {
            return (
                <SprkButton
                    variant="tertiary"
                    idString="button-9"
                    analyticsString="button-9-analytics"
                    isSpinning
                >
                    <SprkSpinner variant="secondary" />
                </SprkButton>
            );
        }

        let gamesList = [];
        if (this.state.games) {
            for (let i = 0; i < this.state.games.length; i++) {
                gamesList.push((
                    <div>
                        <SprkDictionary
                        idString="dictionary-1"
                        keyValuePairs={{
                            'ID': (
                                <a href={(this.state.games[i].multiplayer ? '/game/' : '/singleplayer_game/') + this.state.games[i].gameId}>
                                    {this.state.games[i].gameId}
                                </a>
                            ),
                            'Status': this.state.games[i].status,
                            'Score': this.state.games[i].totalScore,
                            '# Players': this.state.games[i].playerCount,
                            'Created': moment(this.state.games[i].createdAt).format('YYYY-MM-DD h:mma'),
                            'Type': this.state.games[i].multiplayer ? 'Multiplayer' : 'Single Player',
                            'Mode': this.state.games[i].type === 'classic' ? 'Classic' : 'Streak',
                            'Category': getCategoryLabel(this.state.games[i].category),
                            'Time Limit': this.state.games[i].timeLimitSeconds === -1 ? 'Unlimited' : getTimeStr(this.state.games[i].timeLimitSeconds),
                            'Guess Limit': this.state.games[i].guessLimit === 6 ? 'Unlimited' : this.state.games[i].guessLimit,
                        }}
                        />
                    </div>
                ));
            }
        }

        let prevPageButton;
        if (this.state.currentPage > 1) {
            prevPageButton = (
                <SprkButton
                    id='my-games-prev'
                    onClick={() => {
                        let page = this.state.currentPage;
                        page -= 1;
                        if (page < 1) {
                            page = 1;
                        }
                        this.setPage(page);
                    }}
                >
                        Previous
                </SprkButton>
            );
        }
        let nextPageButton;
        if (this.state.currentPage !== this.state.maxPages) {
            nextPageButton = (
                <SprkButton
                    id='my-games-next'
                    onClick={() => {
                        let page = this.state.currentPage;
                        page += 1;
                        this.setPage(page);
                    }}
                >
                    Next
                </SprkButton>
            );
        }

        return (
            <div>
                <SprkHeading
                    additionalClasses="game-header mb-10"
                    element="h2"
                    variant="displayTwo"
                    idString="heading-page-title"
                >
                        My Games
                </SprkHeading>
                <SprkCard additionalClasses="p-30 center wide relative games-card">
                    {gamesList}
                    {/*
                    <SprkPagination
                        currentPage={1}
                        totalItems={5}
                        itemsPerPage={1}
                        onChangeCallback={() => {}}
                    />
                    */}
                    {prevPageButton}
                    {nextPageButton}
                </SprkCard>
                <div className="footer">
                    <a href="/privacy-policy">Privacy Policy</a><br/>
                    <a href="/terms-of-service">Terms of Service</a><br/>
                  </div>
            </div>
        );
    }

}

export default withAuth0(Settings);
