const setCookie = (name, value, days) => {
    var d = new Date();
    d.setTime(d.getTime() + 24*60*60*1000*days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

const getCookie = (name) => {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

function getCategory(category) {
    let valid = [
        'random',
        'sports',
        'animal',
        'robot',
        'nature',
        'people',
        'funny',
    ];
    if (valid.indexOf(category) === -1) {
        return 'random';
    }
    return category;
}

function getCategoryLabel(category) {
    let mapping = {
        'random': 'Random',
        'sports': 'Sports',
        'animal': 'Animals',
        'robot': 'Robots',
        'nature': 'Nature',
        'people': 'People',
        'funny': 'Funny',
    };
    return mapping[category] || 'Random';
}

function getTypeLabel(type) {
    let mapping = {
        'classic': 'Classic',
        'streak': 'Streak',
    };
    return mapping[type] || 'Classic';
}

function secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
}

function getTimeStr(secs) {
    let time = secondsToTime(secs);
    let str = '';
    if (time.m) {
        str += time.m + ' minute';
        if (time.m !== 1) {
            str += 's';
        }
    }
    if (time.s) {
        if (str) {
            str += ' ';
        }
        str += time.s + ' second';
        if (time.s !== 1) {
            str += 's';
        }
    }
    return str;
}

export {
    setCookie,
    getCookie,
    getCategory,
    getCategoryLabel,
    secondsToTime,
    getTimeStr,
    getTypeLabel,
};